@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@import './colors';

@tailwind base;
@tailwind components;
@tailwind utilities;

html:not(.__nextra-app),
html:not(.__nextra-app) body,
html:not(.__nextra-app) #__next,
html:not(.__nextra-app) .app {
  @apply min-h-screen;
  @apply overflow-x-hidden;
}

body {
  @apply bg-page-background;
  @apply text-nakiri-base;
}

a {
  @apply text-accent;

  &:hover {
    @apply text-accent-hover;
  }
}

h1,
h2,
h3,
h4,
h5 {
  @apply font-bold;
  @apply my-3;
}

h1 {
  @apply text-4xl;
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-2xl;
}

h4 {
  @apply text-xl;
}

h5 {
  @apply text-lg;
}

ul {
  @apply list-square;
  @apply list-inside;
  @apply my-5;
  @apply pl-8;
}

p {
  @apply my-3;
}

.input-group {
  @apply my-4;

  .checkbox {
    @apply my-2;
    @apply block;
  }
}

label {
  @apply font-bold;
}

input,
textarea,
select {
  @apply block;
  @apply w-full;
  @apply px-4;
  @apply py-3;
  @apply my-2;
  @apply rounded-md;
  @apply outline-none;
  @apply bg-ayame-primary-100;
  @apply border-2;
  @apply border-ayame-primary-400;

  &:focus {
    @apply border-ayame-primary;
  }

  &[type='checkbox'] {
    @apply inline-block;
    @apply mr-2;
    @apply w-auto;
  }
}

.blacklist-item {
  &:nth-child(odd) {
    @apply bg-ayame-primary-100;
  }
}

.swal2-title {
  @apply bg-accent;
  @apply text-white #{!important};
  @apply p-3 #{!important};
  @apply rounded-t-[5px];
  @apply text-2xl #{!important};
  @apply mb-2 #{!important};
}

.swal2-modal {
  @apply bg-nakiri-base-invert #{!important};
}

.swal2-content,
.swal2-html-container {
  @apply text-nakiri-base #{!important};
}

html:not(.__nextra-app) ::-webkit-scrollbar {
  @apply w-3;
}

html:not(.__nextra-app) ::-webkit-scrollbar-track {
  @apply bg-ayame-primary-300;
}

html:not(.__nextra-app) ::-webkit-scrollbar-thumb {
  @apply bg-ayame-primary-700;

  &:hover {
    @apply bg-ayame-primary-800;
  }
}

@import './progress';
